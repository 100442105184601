module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":400,"quality":75,"withWebp":true,"loading":"lazy","linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"backgroundColor":"transparent","showCaptions":false,"markdownCaptions":false,"withAvif":false,"decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QB1G9SLDM3","AW-662240981"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Urlavet Hayvan Hastanesi","short_name":"Urlavet","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/images/logo/logo.svg","legacy":false,"cache_busting_mode":"none","include_favicon":true,"crossOrigin":"use-credentials","theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
